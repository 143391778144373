import { Link } from 'gatsby';
import get from 'lodash/get';
import React, { useState } from 'react';

import { AiFillGithub, AiFillLinkedin } from 'react-icons/ai';
import { ImStackoverflow } from 'react-icons/im';
import { SiDevDotTo, SiLeetcode } from 'react-icons/si';
import { FaRssSquare, FaDiscord } from "react-icons/fa";

import profileImgTiny from '../../../images/profile-tiny.jpg';
import profileImg from '../../../images/profile.jpg';
import ThemeToggle from '../../UI/theme-toggler';
import { useEffect } from 'react';

const classes = {
  wrapper: 'block mb-4 md:flex',
  imageWrapper: 'w-full max-w-150',
  image:
    'cursor-pointer rounded-full transform transition-all duration-150 hover:scale-105 shadow-3xl border-4 border-gray',
  imageContainerForModal: 'z-10 fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-red',
  imageFromModal:
    'w-96 aspect-square rounded transform transition-all duration-150 shadow-3xl border-4 border-gray',
  contentWrapper: 'flex-none pt-6 md:pt-1 md:flex-1 md:pl-20',
  name:
    'text-4xl sm:text-5xl text-gray-900 dark:text-blue-400 font-bold leading-tight hover:text-black',
  description: 'text-gray-600 dark:text-gray-100 text-lg',
  emailContainer: 'text-gray-600 dark:text-gray-100 py-2 text-md',
  email: 'text-blue-600 dark:text-blue-400 dark:font-semibold',
  list: 'uppercase tracking-wider',
  item: 'inline list-none pr-2 sm:pr-3',
  link:
    'inline-block py-2 font-semibold text-gray-600 text-2xl sm:text-3xl hover:text-gray-800 dark:text-white dark:hover:text-gray-500',
  button: 'font-semibold rounded-md w-fit py-1.5 px-8 mt-1.5 text-white bg-gray-700 dark:text-gray-900 dark:bg-white inline-flex gap-2.5 items-center',
  toggleWrapper: 'mt-5 h-4 md:h-1 text-center',
};

const Header = ({ metadata = {}, noBlog = false }) => {
  const [imageModal, setImageModal] = useState(false)

  const twitter = get(metadata, 'twitter', false);
  const github = get(metadata, 'github', false);
  const linkedin = get(metadata, 'linkedin', false);
  const devto = get(metadata, 'devto', false);
  const stackoverflow = get(metadata, 'stackoverflow', false);
  const leetcode = get(metadata, 'leetcode', false);
  const discord = get(metadata, 'discord', false);
  const email = get(metadata, 'email', false);

  // doesn't work on live site
  // useEffect(() => {
  //   // preload bigger profile image for bigger modal view
  //   const handleImagePreload = () => {
  //     const img = new Image();
  //     img.src = profileImg;
  //   }

  //   window.addEventListener('DOMContentLoaded', handleImagePreload)

  //   return () => {
  //     window.removeEventListener('DOMContentLoaded', handleImagePreload)
  //   }
  // }, [])

  return (
    <>
      {imageModal && <div onClick={(e) => { e.target === e.currentTarget && setImageModal(false) }} className={classes.imageContainerForModal} style={{ background: 'rgba(0,0,0,0.5)' }}>
        <img className={classes.imageFromModal} src={profileImg} alt={metadata.name} />
      </div>}
      <div className={classes.wrapper}>
        <div className={classes.imageWrapper}>
          {/* <Link to="/"> */}
          <img onClick={() => setImageModal(true)} className={classes.image} src={profileImgTiny} alt={metadata.name} />
          {/* </Link> */}
          <div className={classes.toggleWrapper}>
            <ThemeToggle />
          </div>
        </div>
        <div className={classes.contentWrapper}>
          <h1 className={classes.name}>
            <Link to="/">{metadata.name}</Link>
          </h1>
          <p className={classes.description}>{metadata.description}</p>
         {email && <>
          <p className={classes.emailContainer}>
            Email at{' '}
            <a href={`mailto:${email}`} target='blank' className={classes.email}>{email}</a>
          </p>
          <hr className='w-2/3 sm:w-1/4' />
          </>}
          <ul className={classes.list}>
            {linkedin && (
              <li className={classes.item}>
                <a className={classes.link} href={linkedin}>
                  <AiFillLinkedin />
                </a>
              </li>
            )}
            {github && (
              <li className={classes.item}>
                <a className={classes.link} href={github}>
                  <AiFillGithub />
                </a>
              </li>
            )}
            {twitter && (
              <li className={classes.item}>
                <a className={classes.link} href={twitter}>
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>
                </a>
              </li>
            )}
            {discord && (
              <li className={classes.item}>
                <a className={classes.link} href={discord}>
                  <FaDiscord />
                </a>
              </li>
            )}
            {stackoverflow && (
              <li className={classes.item}>
                <a className={classes.link} href={stackoverflow}>
                  <ImStackoverflow />
                </a>
              </li>
            )}
            {leetcode && (
              <li className={classes.item}>
                <a className={classes.link} href={leetcode}>
                  <SiLeetcode />
                </a>
              </li>
            )}
            {devto && (
              <li className={classes.item}>
                <a className={classes.link} href={devto}>
                  <SiDevDotTo />
                </a>
              </li>
            )}
            <li className={classes.item}>
              <a className={classes.link} href="/rss.xml">
                <FaRssSquare />
              </a>
            </li>
          </ul>
          {!noBlog && (
            <Link className={classes.button} to="/blog">
              {/* <RiPencilFill /> */}
              Blogs
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
